import React, { createContext, useContext, useState } from "react";

const defaultState = {
  savedBoats: [],
  setSavedBoats: () => {},
};

const BoatsContext = createContext(defaultState);

export const ContextProvider = ({ children }) => {
  const [savedBoats, setSavedBoats] = useState([]);

  const contextValues = {
    savedBoats,
    setSavedBoats,
  };

  return (
    <BoatsContext.Provider value={contextValues}>
      {children}
    </BoatsContext.Provider>
  );
};

export const useBoatsContext = () => {
  const context = useContext(BoatsContext);
  if (context === undefined || context === null) {
    throw new Error(
      `useBoatsContext must be called within SimpleContextProvider`
    );
  }
  return context;
};
