exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bootsverleih-js": () => import("./../../../src/pages/bootsverleih.js" /* webpackChunkName: "component---src-pages-bootsverleih-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-ferienwohnung-js": () => import("./../../../src/pages/ferienwohnung.js" /* webpackChunkName: "component---src-pages-ferienwohnung-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-routen-js": () => import("./../../../src/pages/routen.js" /* webpackChunkName: "component---src-pages-routen-js" */)
}

